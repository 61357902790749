<!-- Finder expansible de Tipos de Modalidades -->

<template>
  <div class="modalidades_XM" v-if="schema">      
       
      <!-- Botones Mto -->
      <div class="conflex contenedor" style="justify-content:space-between;width:140px">
        <div>        
          <btramto          
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="xm"
            :estado="estado"
            @onEvent="event_btra_Mto">                
          </btramto>
        </div>
      </div>

      <!-- Controles del Mto -->
      <div class="contenedor">    

        <div class="columna">
          <div class="conflex" style="align-items: center;">
            <v-text-field
              style="width:10%"
              v-bind="$input"
              v-model="schema.ctrls.id.value"
              :label="schema.ctrls.id.label"
              :disabled="!is_edit || estado=='editar'">
            </v-text-field>

            <v-text-field
              style="width:70%"
              v-bind="$input"
              v-model="schema.ctrls.name.value"
              :label="schema.ctrls.name.label"
              :disabled="!is_edit">            
            </v-text-field>

            <v-checkbox
              style="width:20%;padding-left:15px;"
              v-bind="$checkbox"
              v-model="schema.ctrls.pun.value"
              :label="schema.ctrls.pun.label"
              :disabled="!is_edit">
            </v-checkbox>
          </div>
        </div>                                        
      </div>
    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js"; 
  import plugs from "@/common/general_plugs"; 
  const btramto = () => plugs.groute("btramto.vue", "comp");   
     
  export default {
    mixins: [mixinMto],  
    components: { btramto },
    props: {
      padre: { type:String, default: '' },    
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      auxRecord: { type:Object, default: ()=> {}}, 
      disparo: { type:Boolean, default:false }, 
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'modalidades_M',
        stName:'stMmodalidades',       
      };
    },  
    

    methods: {
      ini_data() { 
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  
        
        // entorno        
        this.Entorno.header.header= false;          
        this.Entorno.btra="XM";  
      },


      // compruebo si existe el ID antes de guardar
      guardar_nuevo_before() {
        if (this.$store.state[this.padre].read_records.filter(elem=> elem.id==this.schema.ctrls.id.value).length) {
          this.$root.$alert.open('El ID introducido ya existe', 'error');
          return false;
        }

        return true;
      },


      guardar_fin(apiResult) {
        if (apiResult.sql.error || !apiResult.r.length) return;
        this.$store.commit(this.padre + '/update_record', { opcion:'guardar', record:apiResult.r[0][0], id:apiResult.r[0][0].id });        
      }

    }
};
</script>
